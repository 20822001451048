<template>
  <v-data-table
    :items="items"
    :headers="headersWithScores"
    item-key="uniqueEntityId"
    :expanded.sync="expanded"
    :sort-by="sortBy"
    sort-desc
    show-expand
    fixed-header
    :group-by="groupBy"
    group-desc
    :dense="dense"
    @click:row="clicked"
  >
    <template v-slot:item.information.names="{ value }">
      <v-tooltip right color="primary" max-width="750px">
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="d-inline-block text-truncate"
            style="max-width: 25vw;">
          {{value.join(" ,")}}
          </span>
        </template>
        <span>{{ value }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.max_score="{ value }">
      <v-chip small> {{ Math.round(value * 100)  }} </v-chip>
    </template>

    <template v-slot:item.postScore="{ value }">
      <v-chip small> {{ Math.round(value * 100) / 100 }} </v-chip>
    </template>

    <!--Exposing out all the modifiable data slots-->
    <template
      v-for="slotValue in itemDataSlots"
      v-slot:[itemSlotName(slotValue)]="{ item, header, value }"
    >
      <slot :name="itemSlotName(slotValue)" :slotProps="{ item, header, value }" />
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-0">
        <v-col xl="10">
          <EntityDetails :entity="item"/>
        </v-col>
      </td>
    </template>
  </v-data-table>
  
</template>

<script>
import EntityDetails from "@/components/entity/EntityDetails";

export default {
  components: { EntityDetails },
  props: {
    items: Array,
    headers: Array,
    isLoading: Boolean,
    itemDataSlots: Array,
    searchedNameRole: String,
    sortBy: {
      type: String,
      default: "max_score",
    },
    groupBy: {
      type: String,
      default: "category",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headersWithScores() {
      const scoreHeaders = [];

      // if (!this.isHeaderExists('max_score')) {
      //   scoreHeaders.push({
      //     text: 'Score',
      //     value: 'max_score',
      //   });
      // }

      // if (!this.isHeaderExists('postScore')) {
      //   if (this.items.some((item) => item.postScore > 0)) {
      //     scoreHeaders.push({
      //       text: 'Aggregated score',
      //       value: 'postScore',
      //       align: 'center',
      //     });
      //   }
      // }

      return [...this.headers, ...scoreHeaders];
    },
  },
  methods: {
    clicked(value) {
      const index = this.expanded.indexOf(value);
      if (index >= 0) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(value);
      }
    },
    itemSlotName(slotValue) {
      return `item.${slotValue}`;
    },
    isHeaderExists(headerValue) {
      return this.headers.find((header) => header.value === headerValue);
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
};
</script>

<style scoped>
tr{
  height: 1570px !important;
}
</style>
