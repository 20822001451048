const formatItem = (item) => {
  if (item.type) {
    return `${item.value} (${item.type})`;
  }
  return `${item.value}`;
};

export default {
  computed: {
    formattedItems() {
      return this.items.map(formatItem);
    },
  },
};
