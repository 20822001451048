<template>
  <InformationItem v-bind="$props" :items="formattedItems">
    <template v-slot:title>Images</template>
    <template v-slot:item="{item}">
      <v-img max-height="240px" max-width="240px" contain :src="item.link"
             :alt="item.note"
             lazy-src="/img/blank.svg"
      />
    </template>
  </InformationItem>
</template>

<script>
import InformationItem from '@/components/entity/items/InformationItem';
import linkMixins from './mixins/linkFormatterMixins';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
  mixins: [linkMixins],
};
</script>
