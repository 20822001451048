import InformationItem from './InformationItem';
import AddressItem from './AddressItem';
import GenericAddressItem from './GenericAddressItem';
import ApproximateDateItem from './ApproximateDateItem';
import CompanyItem from './CompanyItem';
import DateItem from './DateItem';
import GeneralInfoItem from './GeneralInfoItem';
import IdentificationDocumentItem from './IdentificationDocumentItem';
import ImageLinkItem from './ImageLinkItem';
import MailItem from './MailItem';
import NameItem from './NameItem';
import PhoneItem from './PhoneItem';
import ProgramItem from './ProgramItem';
import RelevantLinkItem from './RelevantLinkItem';
import RelevantPlaceItem from './RelevantPlaceItem';
import SourceLinkItem from './SourceLinkItem';
import ZipCodeItem from './ZipCodeItem';

export default {
  InformationItem,
  AddressItem,
  GenericAddressItem,
  ApproximateDateItem,
  CompanyItem,
  DateItem,
  GeneralInfoItem,
  IdentificationDocumentItem,
  ImageLinkItem,
  MailItem,
  NameItem,
  PhoneItem,
  ProgramItem,
  RelevantLinkItem,
  RelevantPlaceItem,
  SourceLinkItem,
  ZipCodeItem,
};
