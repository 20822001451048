<template>
  <InformationItem v-bind="$props" :items="formattedItems" compact="true">
    <template v-slot:title>Dates</template>
  </InformationItem>
</template>

<script>
import InformationItem from '@/components/entity/items/InformationItem';
import valueFormatterMixins from './mixins/valueFormatterMixins';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
  mixins: [valueFormatterMixins],
};

</script>
