<template>
  <InformationItem v-bind="$props" :items="formattedItems" compact="true">
    <template v-slot:title>Relevant places</template>
  </InformationItem>
</template>

<script>
import InformationItem from './InformationItem';
import valueFormatterMixins from './mixins/valueFormatterMixins';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
  mixins: [valueFormatterMixins],
};
</script>
