<template>
  <InformationItem v-bind="$props" :items="formattedItems">
    <template v-slot:title>Other information</template>
    <!--    todo group by title-->
  </InformationItem>
</template>

<script>
import InformationItem from '@/components/entity/items/InformationItem';
import valueFormatterMixins from './mixins/valueFormatterMixins';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
  mixins: [valueFormatterMixins],
};
</script>
