<template>
  <v-card v-if="compact" class="ma-1" flat>
    <v-card-title class="subtitle-1 pb-0">
      <slot name="title">{{itemType}}</slot>
    </v-card-title>
    <v-card-text class="selectable pa-1">
        <v-chip v-for="(item, index) in items" :key="index"
                class="ma-2"
                color="blue"
                outlined
                pill
        >
          <slot name="item" v-bind="{items, item, index}">{{item}}</slot>
        </v-chip>
    </v-card-text>
  </v-card>
  <v-card v-else class="ma-1" flat>
    <v-card-title class="subtitle-1">
      <slot name="title">{{itemType}}</slot>
    </v-card-title>
    <v-card-text v-for="(item, index) in items" :key="index" class="selectable py-1 ">
        <slot name="item" v-bind="{items, item, index}">{{item}}</slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InformationItem',
  props: ['items', 'itemType', 'compact'],
};
</script>
