<template>
  <InformationItem v-bind="$props" :items="items" compact="true">
    <template v-slot:title> Address </template>
    <template v-slot:item="{item}">
      <span v-if="item.value"> {{item.value}} </span>
    </template>
  </InformationItem>
</template>

<script>
import InformationItem from './InformationItem';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
};
</script>
