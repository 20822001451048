<template>
  <v-container fluid>
    <v-row align="stretch" no-gutters class="pa-0 ma-0">
      <component
        v-bind:is="componentsNames[item[0]]"
        v-for="(item, index) in items"
        :key="index"
        :items="item[1]"
        :itemType="item[0]"
      />
    </v-row>
    <v-row class="ma-5">
      <v-btn block depressed @click="toggle">
        {{ rawDataButtonCaption }}
      </v-btn>
      <v-col v-if="showRawData && rawData">
        <VueJsonPretty :data="JSON.parse(rawData)" :deep="2" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import apiClient from "@/services/apiClient";
import itemComponents from "./items";
import GeneralInfoItem from "./items/GeneralInfoItem";
import ApproximateDateItem from "./items/ApproximateDateItem";
import RelevantPlaceItem from "./items/RelevantPlaceItem";
import RelevantLinkItem from "./items/RelevantLinkItem";
import ImageLinkItem from "./items/ImageLinkItem";
import SourceLinkItem from "./items/SourceLinkItem";
import IdentificationDocumentItem from "./items/IdentificationDocumentItem";
import ZipCodeItem from "./items/ZipCodeItem";
import ProgramItem from "./items/ProgramItem";
import PhoneItem from "./items/PhoneItem";
import NameItem from "./items/NameItem";
import MailItem from "./items/MailItem";
import CompanyItem from "./items/CompanyItem";
import GenericAddressItem from "./items/GenericAddressItem";

export default {
  props: {
    // id: String,
    entity: Object,
  },
  components: {
    ...itemComponents,
    VueJsonPretty: () => import("vue-json-pretty"),
  },
  data() {
    return {
      componentsNames: {
        names: NameItem,
        dates: ApproximateDateItem,
        locations: RelevantPlaceItem,
        zips: ZipCodeItem,
        phones: PhoneItem,
        emails: MailItem,
        images: ImageLinkItem,
        companies: CompanyItem,
        idDocs: IdentificationDocumentItem,
        programs: ProgramItem,
        generalInfo: GeneralInfoItem,
        links: RelevantLinkItem,
        sources: SourceLinkItem,
        genericAddress: GenericAddressItem,
        // countries: CountryItem,
        // year: YearItem,
      },
      items: [],
      showRawData: false,
      rawData: null,
      information: null,
    };
  },
  computed: {
    rawDataButtonCaption() {
      return this.showRawData ? "Hide raw data" : "Show raw data";
    },
  },
  methods: {
    groupOrder(itemType) {
      switch (itemType) {
        case "images":
          return 0;
        case "names":
          return 10;
        case "dates":
          return 20;
        case "locations":
          return 30;
        case "zips":
          return 40;
        case "phones":
          return 50;
        case "emails":
          return 60;
        case "companies":
          return 70;
        case "idDocs":
          return 80;
        case "programs":
          return 90;
        case "generalInfo":
          return 100;
        case "links":
          return 110;
        case "sources":
          return 120;
        default:
          return Number.MAX_VALUE;
      }
    },

    // async loadData(id) {
    //   const resp = await apiClient.getEntity(id);
    //   this.information = resp.data.information;
    //   this.rawData = resp.data.rawData;
    // },
    async toggle() {
      this.showRawData = !this.showRawData;
      // if (this.showRawData && !this.rawData) {
      //   await this.loadData(this.entity.uniqueEntityId);
      // }
    },

    getItems(information) {
      return Object.entries(information).sort(
        (a, b) => this.groupOrder(a[0]) - this.groupOrder(b[0])
      );
    },
  },
  async created() {
    // if (this.id) {
    //   await this.loadData(this.id);
    // } else {
    this.information = this.entity.information;
    this.rawData = this.entity.rawData;
    // }
    this.items = this.getItems(this.information);
  },
};
</script>

<style scoped></style>
