<template>
  <InformationItem v-bind="$props" :items="formattedItems" compact="true">
    <template v-slot:title>Address</template>
    <template v-slot:item="{item}">
      <span v-if="item.country">Country: {{item.country}}&nbsp;</span>
      <span v-if="item.city">City: {{item.city}}&nbsp;</span>
      <span v-if="item.state">State: {{item.state}}&nbsp;</span>
      <span v-if="item.address">Address: {{item.address}}</span>
    </template>
  </InformationItem>
</template>

<script>

import InformationItem from '@/components/entity/items/InformationItem';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
};
</script>
