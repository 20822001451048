<template>
  <InformationItem v-bind="$props" :items="formattedItems" compact="true">
    {{items}}
    <template v-slot:title>Source links</template>
    <template v-slot:item="{item}">
      <a :href="item.link" :alt="item.note">{{item.link}}</a>
    </template>
  </InformationItem>
</template>

<script>
import InformationItem from '@/components/entity/items/InformationItem';
import linkFormatterMixins from './mixins/linkFormatterMixins';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
  mixins: [linkFormatterMixins],
};
</script>
