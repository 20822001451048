<template>
  <InformationItem v-bind="$props" compact="true">
    <template v-slot:title>Companies</template>
  </InformationItem>
</template>

<script>
import InformationItem from './InformationItem';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
};
</script>
