<template>
  <InformationItem v-bind="$props">
    <template v-slot:title>Programms</template>
  </InformationItem>
</template>

<script>
import InformationItem from './InformationItem';

export default {
  components: { InformationItem },
  props: ['items', 'itemType'],
};
</script>
